<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="rich-cke prose max-w-full px-3 xl:pl-0"
    data-testid="rich-cke"
    :class="[richTextCkeditorCva({ width: data.width })]"
    v-html="formatContent()"
  ></div>
</template>

<script lang="ts" setup>
import type { StrapiSectionRichTextCkeditor } from '@autobid/strapi-integration/typescript/strapi/sections/SectionRichTextCkeditor'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import { addCmsUrl } from '@autobid/ui/utils/addCmsUrl'
import { addBlankToExternalLinks } from '@autobid/ui/utils/addBlankToExternalLinks'
import { richTextCkeditorCva } from '@autobid/ui/utils/cva/richTextCkeditorCva'
import { useCmsConfig } from '@autobid/strapi-integration/composable/useCmsConfig'

interface Props {
  data: StrapiComponentProps<StrapiSectionRichTextCkeditor>
}

const props = defineProps<Props>()

const { url: cmsUrl } = useCmsConfig()
const { SELF_URL } = useRuntimeConfig().public
const { host } = new URL(SELF_URL)

const formatContent = () => {
  let result = addCmsUrl(props.data.content, cmsUrl)

  result = addBlankToExternalLinks(result, host)

  return result
}
</script>

<style lang="scss">
@import './style.scss';
</style>
