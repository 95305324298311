import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const richTextCkeditorCva = cva(['w-full'], {
  variants: {
    width: {
      'w-full': 'w-full',
      'w-1/2': 'md:w-1/2',
      'w-1/3': 'md:w-1/3',
      'w-2/3': 'md:w-2/3',
      'w-1/6': 'md:w-1/6'
    }
  },
  defaultVariants: {
    width: 'w-full'
  }
})

export type RichTextCkeditorProps = VariantProps<typeof richTextCkeditorCva>
